
import React from 'react';
// import { R4 } from '@ahryman40k/ts-fhir-types';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid2, Icon, InputLabel, MenuItem, Select } from '@mui/material';
import FhirPatchView from './FhirPatchView';
import { withTranslation, WithTranslation } from 'react-i18next';

interface ResourceCompareProps<RowData extends object> extends WithTranslation {
  type: string,
  data: RowData[],
  open: boolean,
  onClose: () => void,
}

const ResourceCompare = (props: ResourceCompareProps<any>) => {
  const { type, data, open, onClose, t } = props;

  const items = data.map(d => ({
    id: d.resource.id,
    title: d.resource.title || d.resource.name || 'id:' + d.resource.id,
    version: d.resource.version,
  }));

  const [from, setFrom] = React.useState<any>('');
  const [to, setTo] = React.useState<any>('');

  const handleClose = () => {
    setFrom('');
    setTo('');
    onClose();
  }

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth='lg'>
      <DialogTitle>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <Icon>difference</Icon>
          &nbsp;<span>{t('Compare Resources')}</span>
        </div>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('Select the resources to compare.')}
        </DialogContentText>
        <Grid2 container>
          <Grid2 container size={{ xs: 5 }}>
            <FormControl size='small' fullWidth={true}>
              <InputLabel id="from-label">{t('From')}</InputLabel>
              <Select labelId="from-label" value={from} onChange={(event) => setFrom(event.target.value)}>
                {items.map(item => <MenuItem value={item.id}>
                  {item.title} {item.version}
                </MenuItem>)}
              </Select>
            </FormControl>
          </Grid2>
          <Grid2 container size={{ xs: 1 }} />
          <Grid2 container size={{ xs: 5 }}>
            <FormControl size='small' fullWidth={true}>
              <InputLabel id="to-label">{t('To')}</InputLabel>
              <Select labelId="to-label" value={to} onChange={(event) => setTo(event.target.value)}>
                {items.map(item => <MenuItem value={item.id}>
                  {item.title} {item.version}
                </MenuItem>)}
              </Select>
            </FormControl>
          </Grid2>
          {
            (from !== '' && to !== '') ?
              <Grid2 container size={{ xs: 12 }} style={{marginTop: '4ex'}}>
                <FhirPatchView type={type} id={from} to={to}></FhirPatchView>
              </Grid2> :
              <></>
          }
        </Grid2>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} size="small" color="primary" variant="contained">Close</Button>
      </DialogActions>
    </Dialog>
  )
}

export default withTranslation()(ResourceCompare)
