import React from "react"
import { Tooltip } from "@mui/material"

interface CodeableConceptProps {
    cc: any
}

function CodeableConcept(props: CodeableConceptProps) {
    const { cc } = props
    const display = cc?.text || cc?.coding[0]?.display

    return (
        <Tooltip
            title={
                <React.Fragment>
                    <b>codeable</b> concept
                </React.Fragment>
            }
        >
            <span>{display}</span>
        </Tooltip>
    )
}

export default CodeableConcept
