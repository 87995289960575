import React, { ChangeEvent } from 'react';
import SmartContext from './SmartContext';
import { TextField, MenuItem, Button, ListItemIcon, ListItemText, Card, CardContent, CardActions, Grid2, FormControl, Chip, Box, CircularProgress, FormControlLabel, Checkbox } from '@mui/material';
import { AttachFile } from '@mui/icons-material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import {
  LocalizationProvider,
  DesktopDatePicker,
} from '@mui/x-date-pickers';
import { withTranslation, WithTranslation } from 'react-i18next';

export const Upload = withTranslation()(function (props: WithTranslation) {
  const { t } = props;

  const fhirClient = React.useContext(SmartContext).client
  const [context, setContext] = React.useState(fhirClient)
  const [loading, setLoading] = React.useState(false)
  const [fields, setFields] = React.useState<any>({
    'system': 'http://snomed.info/sct',
    'module': '900000000000207008',
    'date': new Date().toISOString().slice(0, 10),
    'kind': 'snapshot',
    'experimental': false,
  })
  const [files, setFiles] = React.useState<any[]>([])

  if (context !== fhirClient) {
    setContext(fhirClient)
  }

  const handleFileChange = (event: any) => {
    const newFiles = files.concat([...event.target.files])
    setFiles(newFiles)
  }
  const handleFileRemove = (idx: number) => () => {
    files.splice(idx, 1)
    setFiles([...files])
  }
  const handleModuleChange = (event: ChangeEvent) => {
    const { value } = event.target as any
    setFields({ ...fields, 'module': value })
  }
  const handleKindChange = (event: ChangeEvent) => {
    const { value } = event.target as any
    setFields({ ...fields, 'kind': value })
  }
  const handleExperimentalChange = (event: ChangeEvent) => {
    setFields({ ...fields, 'experimental': !fields.experimental })
  }
  const handleDateChange = (date: Date | null) => {
    setFields({ ...fields, 'date': date?.toISOString().slice(0, 10) })
  }
  const handleSubmit = () => {
    // console.log('Files', files)

    var formData = new FormData();

    ['system', 'kind'].forEach(k => {
      formData.append(k, fields[k])
    })
    formData.append('version',
      (fields.experimental ? 'http://snomed.info/xsct/' : 'http://snomed.info/sct/')
      + fields.module + '/version/' + fields.date.replace(/-/g, ""))


    files.forEach(f => {
      // console.log('FILE', f)
      formData.append("file[]", f)
    })

    setLoading(true)
    fhirClient?.request({
      method: 'POST',
      url: 'CodeSystem/$x-upload-external',
      mode: 'cors',
      cache: 'no-cache',
      body: formData,
    }).then((result) => {
      setLoading(false)
      console.log(result)
    }).catch((error) => {
      setLoading(false)
      console.log(error)
    })

    // var request = new XMLHttpRequest();
    // request.onreadystatechange = (ev: Event) => {
    //     console.log('RSC', ev)
    // }
    // request.open("POST", "http://localhost:8090/fhir/CodeSystem/$x-upload-external");
    // request.send(formData);
    // console.log("SENT", request.status);
    // setLoading(false)
  }

  return (
    <React.Fragment>
      <Card square>
        <CardContent>
          <Grid2 container justifyContent="flex-start" alignItems="center">

            <Grid2 container size={{ xs: 2 }} justifyContent="flex-start">
              <FormControl>
                <TextField id="module" label={t('Module')}
                  value={fields.module}
                  fullWidth={true}
                  margin="dense"
                  onChange={handleModuleChange}
                  select >
                  <MenuItem value="900000000000207008" >International</MenuItem>
                  <MenuItem value="11000221109" >Argentina</MenuItem>
                  <MenuItem value="32506021000036107" >AU</MenuItem>
                  {/* <MenuItem value="21000032107" >AU - PAH</MenuItem> */}
                  <MenuItem value="11000172109" >Belgium</MenuItem>
                  <MenuItem value="20611000087101" >Canada</MenuItem>
                  <MenuItem value="554471000005108" >Denmark</MenuItem>
                  <MenuItem value="11000181102" >Estonia</MenuItem>
                  <MenuItem value="11000315107">France</MenuItem>
                  <MenuItem value="11000220105" >Ireland</MenuItem>
                  <MenuItem value="51000202101" >Norway</MenuItem>
                  <MenuItem value="450829007" >Latinoamérica</MenuItem>
                  <MenuItem value="11000146104" >Netherlands</MenuItem>
                  <MenuItem value="15561000146104" >Netherlands patient-friendly</MenuItem>
                  <MenuItem value="21000210109" >New Zealand</MenuItem>
                  <MenuItem value="51000202101" >Norway</MenuItem>
                  <MenuItem value="449081005" >Spain</MenuItem>
                  <MenuItem value="45991000052106" >Sweden</MenuItem>
                  <MenuItem value="83821000000107" >UK (Drug+Clinical)</MenuItem>
                  {/* <MenuItem value="999000031000000106" >UK Edition</MenuItem> */}
                  <MenuItem value="999000021000000109" >UK (Clinical only)</MenuItem>
                  {/* <MenuItem value="999000041000000102" >UK</MenuItem> */}
                  <MenuItem value="731000124108" >United States</MenuItem>
                  <MenuItem value="5631000179106" >Uruguay</MenuItem>
                  <MenuItem value="332351000009108" >Veterinary Terminology</MenuItem>
                  <MenuItem value="999991001000101" >IPS Terminology</MenuItem>
                  <MenuItem value="11010000107" >LOINC Extension</MenuItem>
                </TextField>
              </FormControl>
            </Grid2>

            <Grid2 container size={{ xs: 2 }}>
              <FormControl>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    format="yyyyMMdd"
                    label="EffectiveTime"
                    value={fields.date}
                    onChange={handleDateChange}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid2>

            <Grid2 container size={{ xs: 2 }}>
              <FormControl>
                <TextField id="fileType" label={t('File Type')}
                  value={fields.kind}
                  fullWidth={true}
                  margin="dense"
                  onChange={handleKindChange}
                  select >
                  <MenuItem value="snapshot" >Snapshot&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</MenuItem>
                  <MenuItem value="full" >Full&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</MenuItem>
                </TextField>
              </FormControl>
            </Grid2>

            <Grid2 container size={{ xs: 2 }}>
              <FormControlLabel
                control={<Checkbox
                  value={fields.experimental}
                  color="secondary"
                  onChange={handleExperimentalChange}
                />}
                label={t('Experimental version')}
                labelPlacement="end"
              />
            </Grid2>

            <Grid2 size={{ xs: 12 }} title="SNOMED CT Version" >
              &nbsp;
                            {/* <Box display="flex">{'http://snomed.info/sct/' + fields.module + '/version/' + fields.date.replace(/-/g, "")}</Box> */}
            </Grid2>

            <Grid2 container size={{ xs: 3 }}>
              <FormControl>
                <input
                  hidden
                  accept=".zip,application/zip"
                  id="contained-button-file"
                  multiple
                  type="file"
                  name="rf2File"
                  onChange={handleFileChange}
                />
                <label htmlFor="contained-button-file">
                  <ListItemText>
                    <ListItemIcon><Button
                      variant="outlined"
                      color={files.length > 0 ? 'primary' : 'secondary'}
                      component="span"><AttachFile /> {t('SelectRF2')}</Button></ListItemIcon>
                  </ListItemText>
                </label>
              </FormControl>
            </Grid2>

            <Grid2 size={{ xs: 8 }} title="RF2 files">
              <Box display="flex">
                {
                  files.map((f: any, idx) =>
                    <Chip key={idx} onDelete={handleFileRemove(idx)} label={f.name} color="primary" />
                  )
                }
                {
                  files.length === 0 && t('SelectRF2Prompt')
                }
              </Box>
            </Grid2>

          </Grid2>
        </CardContent>
        <CardActions >
          <div>
            <Button
              sx={{ ml: '8px', width: '164px' }}
              disabled={files.length === 0 || loading}
              variant="contained"
              color="primary"
              onClick={handleSubmit}
            >
              {loading ? t('doingIndexing') : t('doIndex')}
            </Button>
            {loading &&
              <CircularProgress size={24} sx={{ position: 'relative', top: '8px', left: -8 }}/>
            }
          </div>
        </CardActions>
      </Card>
    </React.Fragment>
  );
});
