import React from "react";
import { createTheme } from '@mui/material/styles';
import { TextField, FormControl, Button, Grid2, Card, CardHeader, CardContent, CircularProgress } from "@mui/material";
import fetch from 'cross-fetch';
import { useQuery } from 'react-query';
import Endpoint from "./Endpoint";
import Header from "./Header";
import { useLocation, Link } from "react-router-dom";
import { Autocomplete } from "@mui/lab";

declare var ENV: {
  ONTO: { [key: string]: any }
};

function useSearch() {
  return new URLSearchParams(useLocation().search);
}

let theme = createTheme();

const defaultClientId = 'onto-ui';
const background = '#eaeff1';

const getMDUrl = (url: string) => {
  try {
    var parsedUrl = new URL(url);
    if (parsedUrl.pathname.endsWith('/')) {
      parsedUrl.pathname = parsedUrl.pathname.slice(0, -1);
    }
    parsedUrl.pathname = parsedUrl.pathname + '/';
    return new URL('metadata?_format=json', parsedUrl);
  } catch (error) {
    return '';
  }
}

const getMetdata = (url: string) => {
  const metadataUrl = getMDUrl(url);
  return fetch(metadataUrl, { headers: { 'Accept': 'application/fhir+json' } })
    .then(response => {
      if (200 !== response.status) throw response

      const KEY = 'oc_knownEndpoints';
      const knownEndpoints = JSON.parse(localStorage.getItem(KEY) || '{}');
      knownEndpoints[url] = true;
      localStorage.setItem(KEY, JSON.stringify(knownEndpoints));

      return response;
    });
}

const Login = function () {

  const KEY = 'oc_knownEndpoints';
  const knownEndpoints:{[key: string]:boolean} = JSON.parse(localStorage.getItem(KEY) || '{}');

  const query = useSearch();
  const iss = query.get('iss') || sessionStorage.getItem('oc_lastEndpoint') || '';
  const defaultUrl = iss.endsWith('/') ? iss.slice(0, -1) : iss;   // strip trailing /

  const [url, setUrl] = React.useState(defaultUrl)
  const [clientId, setClientId] = React.useState(defaultClientId)

  const [enabled, setEnabled] = React.useState(false)

  const { isLoading, isError } = useQuery(['metadata', url], () => getMetdata(url), {
    onError: error => { console.log(error) },
    retry: false,
    refetchOnWindowFocus: false,
  })

  const select = function(selected:string) {
    setUrl(selected);
  }

  React.useEffect(() => {
    const ok = !isLoading && !isError
    if (ok) {
      setClientId(ENV.ONTO.clientIds[url] || defaultClientId)
    } else {
      setClientId(defaultClientId)
    }
    setEnabled(ok)
  }, [isLoading, isError, url])

  return (
    <React.Fragment>
      <Header>
        <Endpoint />
      </Header>
      <main style={{ flex: 1, padding: theme.spacing(10, 10), background: background }}>
        <Card square>
          <CardHeader title="Endpoint details" />
          <CardContent>
            <Grid2
              container
              alignContent="space-between"
              size={{ xs: 12, lg: 8 }}
              spacing={1}
            >
              <Grid2 size={{ xs: 10 }}>
                <FormControl fullWidth>
                  <Autocomplete id="endpoint-url"
                    options={Object.keys(knownEndpoints)}
                    multiple={false}
                    freeSolo={true}
                    autoComplete={true}
                    value={url}
                    onInputChange={(_,value) => select(value)}
                    renderInput={(params:any) => <TextField {...params} label="Terminology Server URL" />}
                    />
                </FormControl>
              </Grid2>
              <Grid2 size={{ xs: 6 }}>
                <FormControl fullWidth>
                  <TextField id="client-id" label="Client Id" variant="outlined" size="small"
                    disabled={!enabled}
                    onChange={(event) => setClientId(event.target.value)}
                    value={clientId} />
                </FormControl>
              </Grid2>
              <Grid2 size={{ xs: 4 }}>
              </Grid2>
              <Grid2 size={{ xs: 2 }}>
                <FormControl fullWidth>
                  {isLoading ? (<CircularProgress />) : (
                    <Link role='button' to={`/launch?iss=${url}&clientId=${clientId}`}>
                      <Button fullWidth disabled={!enabled} title='Login or Connect to FHIR endpoint' variant="contained" color="primary">Connect</Button>
                    </Link>
                  )}
                </FormControl>
              </Grid2>
            </Grid2>
          </CardContent>
        </Card>
      </main>
    </React.Fragment>
  );
}

export default Login;
