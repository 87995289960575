
import React from 'react';
import SmartContext from './SmartContext';
import { Box, Button, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid2, ListItemIcon, ListItemText } from '@mui/material';
import { withTranslation, WithTranslation } from 'react-i18next';
import { AttachFile, NoteAdd } from '@mui/icons-material';

interface ResourceUploadProps extends WithTranslation {
  type: string,
  open: boolean,
  onClose: () => void,
}

interface UploadDetails {
  name: string,
  json: any,
}

const ResourceUpload = (props: ResourceUploadProps) => {
  const { type, open, onClose, t } = props;

  const fhirClient = React.useContext(SmartContext).client
  const [loading, setLoading] = React.useState(false)
  const [messages, setMessages] = React.useState<string[]>([])
  const addMessage = (msg:string) => {
    setMessages((prev:string[]) => [...prev, msg]);
  }
  const [uploadItems, setUploadItems] = React.useState<UploadDetails[]>([]);
  const addUploadItem = (newItem: UploadDetails) => {
    setUploadItems((prevItems: UploadDetails[]) => [...prevItems, newItem]);
  };

  const handleClose = () => {
    setLoading(false);
    setUploadItems([]);
    setMessages([]);
    onClose();
  }

  const handleUpload = () => {
    setMessages([]);

    uploadItems.forEach((item, i) => {
      setLoading(true)
      const id = item.json.id;
      const [method, url] = id ? ['PUT', `${type}/${id}`] : ['POST', type];
  
      fhirClient?.request({
        method: method,
        url: url,
        mode: 'cors',
        cache: 'no-cache',
        body: JSON.stringify(item.json),
        headers: {
          'Content-Type': 'application/fhir+json'
        }
      }).then((result) => {
        // console.log(result)
        setLoading(uploadItems.length !== messages.length + 1)
        addMessage(`${item.name} successfully uploaded.`)
      }).catch((error) => {
        console.log(error)
        setLoading(uploadItems.length !== messages.length + 1)
        addMessage(`${error}`)
      })
    })
  }

  const handleFileChange = (event: any) => {
    setUploadItems([]);
    setMessages([]);

    for (const file of event.target.files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const result = e.target?.result as string;
        if (result) {
          const res = JSON.parse(result);
          if (type === res.resourceType) {
            const details: UploadDetails = {
              json: res,
              name: res.id ? `${file.name} (${res.id})` : file.name,
            };
            addUploadItem(details);
          } else {
            addMessage(`${file.name} was not a ${type} resource - found: ${res.resourceType}`);
          }
        } else {
          addMessage(`No resource loaded for ${file.name}`)
        }
      };
      reader.onerror = (e) => {
        setLoading(false);
        console.log('ERROR', e);
        addMessage(`${e}`);
      }
      reader.readAsText(file, 'UTF-8');
    }
  }
  const handleFileRemove = (idx:number) => {
    setUploadItems((prev) => prev.filter((_, i) => i !== idx));
    setMessages([])
  }

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth='sm'>
      <DialogTitle>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <NoteAdd />
          &nbsp;<span>{t('Upload Resource', {type})}</span>
        </div>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('The resource to upload.')}
        </DialogContentText>
        <Grid2 container>
          <Grid2 container size={{ xs: 12 }}>
            <FormControl style={{display: uploadItems.length > 0 ? 'none' : 'block'}}>
              <input
                hidden
                accept=".json,text/json"
                id="contained-button-file"
                multiple
                type="file"
                name="jsonFile"
                onChange={handleFileChange}
              />
              <label htmlFor="contained-button-file">
                <ListItemText>
                  <ListItemIcon>
                    <Button
                      variant="outlined"
                      color={uploadItems.length > 0 ? 'primary' : 'secondary'}
                      component="span"><AttachFile /> {t('SelectJSON', {type})}
                    </Button>
                  </ListItemIcon>
                </ListItemText>
              </label>
            </FormControl>
          </Grid2>
          <Grid2 size={{ xs: 8 }} title="JSON files">
              <Box sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: 1,
              }}>{
                uploadItems.map((item, idx) => (
                  <Chip key={idx} onDelete={() => handleFileRemove(idx)} label={item.name} color="primary" />
                ))
              }</Box>
              <Box display="block" color={'darkred'}>{
                messages.map(m => <div>{m}</div>)
              }</Box>
            </Grid2>
        </Grid2>
      </DialogContent>
      <DialogActions>
        {loading &&
          <CircularProgress size={24} sx={{ position: 'relative', top: '8px', left: -8 }}/>}
        <Button onClick={handleUpload} size="small" color="primary" variant="contained" disabled={uploadItems.length === 0 || loading}>Upload</Button>
        <Button onClick={handleClose} size="small" color="secondary" variant="contained">Close</Button>
      </DialogActions>
    </Dialog>
  )
}

export default withTranslation()(ResourceUpload)
