import { styled } from '@mui/system';
import React from 'react'
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import TreeRow from './TreeRow';

const TreeItemComponent = styled(TreeItem)({
    '& .MuiTreeItem-content': {
        borderRadius: 0,
        padding: 0,
    },
});

const transformResource = (r: any, path: string) => {
    if ('object' === typeof r) {
        return Object.entries(r).flatMap(([key, val], jdx) => {
            if (Array.isArray(val)) {
                return val.map((elt, idx) => {
                    const nodeId = path + '.' + key + '_' + idx
                    return (
                        <TreeItemComponent key={nodeId} itemId={nodeId} label={<TreeRow label={key} index={idx} val={elt} />}>
                            {transformResource(elt, nodeId)}
                        </TreeItemComponent>
                    )
                })
            } else {
                const nodeId = path + '.' + key
                return [
                    <TreeItemComponent key={nodeId} itemId={nodeId} label={<TreeRow label={key} val={val} />}>
                        {transformResource(val, nodeId)}
                    </TreeItemComponent>
                ]
            }
        })
    } else {
        return []
    }
}

export interface ResourceTreeProps {
    resource: any;
}

const ResourceTree = (props: ResourceTreeProps) => {
    const { resource } = props;
    const [treeData, setTreeData] = React.useState<any[]>([]);

    React.useEffect(() => {
        setTreeData(transformResource(resource, 'root') || []);
    }, [resource])

    return (
        <SimpleTreeView sx={{ flexGrow: 1, background: '#FAFAFA', ml: '2em' }}>
            {treeData}
        </SimpleTreeView>
    )
}

export default ResourceTree;
