import React, { CSSProperties } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import MaterialTable, { Column } from '@material-table/core';
import SmartContext from './SmartContext';
import { CircularProgress, Typography } from '@mui/material';

/*
The FHIRPath patch operations are encoded in a Parameters resource as follows:

Each operation is a Parameter named "operation"
Each operation has a series of parts, using the following parameter names from the table above with these types:
Parameter	Type
type	code
path	string
name	string
value	*
index	integer
source	integer
destination	integer
*/

interface Parameters {
  resourceType: 'Parameters';
  parameter: {
    name: string;
    part: any[];
  }[];
}

interface Operation {
  id: number;
  type: string;
  path: string;
  name: string;
  value: any;
  previous?: any;
  index?: number;
  source?: number;
  destination?: number;
}

interface FhirPatchViewProps extends WithTranslation {
  type: string;
  id: string;
  to?: string;
  version?: number;
  style?: CSSProperties;
}

// const colorMap: { [key: string]: string; } = {
//   replace: 'blue',
//   delete: 'red',
//   insert: 'green',
//   add: 'darkgreen',
// };

function FhirPatchView(props: FhirPatchViewProps) {
  const { type, id, to, version, style, t } = props;
  const smart = React.useContext(SmartContext);
  const fhirClient = smart.client
  const hasDiff = smart.onto?.hasDiff;

  const [loading, setLoading] = React.useState(false)
  const [data, setData] = React.useState<Operation[]>([]);

  React.useEffect(() => {
    let mounted = true

    if (!hasDiff) {
      setData([])
      return
    }

    setLoading(true)
    const url = version ?
      `/${type}/${id}/_history/${version}/$diff` :
      (to ? `/$diff?from=${type}/${id}&to=${type}/${to}` : `/${type}/${id}/$diff`);

    fhirClient?.request(url)
      .then((res: any) => {
        if (mounted) {
          setLoading(false)
          // console.log('RES', res);
          if ('Parameters' !== res.resourceType) {
            return;
          }
          const fhirPatch: Parameters = res;
          const ops: Operation[] = (fhirPatch?.parameter || []).map((p, idx) => {
            const op: Operation = {
              id: idx,
              type: '',
              path: '',
              name: '',
              value: '',
            };

            p.part.forEach(pa => {
              switch (pa.name) {
                case 'type':
                  op.type = t(`Op${pa.valueCode}`);
                  break;
                case 'index':
                  op.index = pa.valueInteger;
                  break;
                case 'path':
                  op.path = pa.valueString;
                  break;
                case 'name':
                  op.name = pa.valueString;
                  break;
                case 'value':
                  Object.keys(pa).forEach(k => {
                    if (k.startsWith('value')) {
                      op.value = JSON.stringify(pa[k]);
                    } else if ('resource' === k) {
                      op.value = pa.resource.resourceType;
                    }
                  })
                  break;
                case 'previousValue':
                  Object.keys(pa).forEach(k => {
                    if (k.startsWith('value')) {
                      op.previous = JSON.stringify(pa[k]);
                    }
                  })
                  break;
              }
            })

            return op;
          });

          setData(ops);
        }
      }, (error) => {
        if (mounted) {
          setLoading(false)
          console.log('ERROR', error);
          // onError(error)
        }
      })
    return () => { mounted = false }
  }, [fhirClient, hasDiff, type, id, to, version, t]);

  return (
    <React.Fragment>
      {loading ? <CircularProgress /> :
        (data.length === 0 ?
          <Typography style={{ marginLeft: '2em', fontWeight: 'bold', ...style }}>No changes</Typography> :
          <MaterialTable
            style={style}
            columns={[
              { title: t('Operation'), field: 'type', width: '4em', },
              { title: t('Path'), field: 'path', },
              { title: t('Name'), field: 'name', width: '8em', },
              { title: t('Index'), field: 'index', width: '4em', align: 'right' },
              { title: t('PrevValue'), field: 'previous', width: '20em', },
              { title: t('Value'), field: 'value', width: '20em', },
            ] as Column<object>[]}
            data={data}
            options={{
              grouping: false,
              headerStyle: {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
              },
              padding: 'dense',
              paging: false,
              pageSize: 10,
              search: false,
              selection: false,
              showTitle: false,
              maxColumnSort: 0,
              toolbar: false,
            }} />
        )
      }
    </React.Fragment>
  );
}
  
export default withTranslation()(FhirPatchView);
