import React from 'react'
import { Card, Snackbar } from '@mui/material';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import MaterialTable from '@material-table/core';
import SmartContext from './SmartContext';
import SnackOutcome from './SnackOutcome';
import { withTranslation, WithTranslation } from 'react-i18next';

interface Entry {
  id: string;
  name: string;
  status: string;
  detail?: string[];
}

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

interface JobsProps extends WithTranslation {
  fetch: boolean;
}

function Jobs(props: JobsProps) {
  const { fetch, t } = props;
  const smart = React.useContext(SmartContext)

  const [loading, setLoading] = React.useState(false);
  const [state, setState] = React.useState<any>({jobs:[]});
  const [alert, setAlert] = React.useState(false);
  const [warn, setWarn] = React.useState<string[]>([]);
  const [data, setData] = React.useState<Entry[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [outcome, setOutcome] = React.useState<any>({});

  const tt = (arg: string) => t(arg) || arg;

  const handleAlertClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setAlert(false)
  };

  // const handleWarnClose = (id: string, reason?: string) => {
  //   if (reason === 'clickaway') {
  //     return
  //   }
  //   setWarn(warn.filter(elt => elt !== id))
  // };

  React.useEffect(() => {
    if (smart.client) {
      let mounted = true;
      (async () => {
        setLoading(true)
        smart.onto?.getJobs()
          .then(response => {
            if (response.status === 401) {
              response.text().then(() => setWarn([t('ApiAuthRequired')]));
            }
            if (response.status > 400) {
              throw response;
            }
            return response;
          })
          .then(response => response.text())
          .then((text) => {
            if (mounted) {
              setState(JSON.parse(text))
              setLoading(false)
            }
          }, (error) => {
            console.log('Failed getting jobs list', error);
            if (mounted) {
              setState({jobs:[]})
              setLoading(false)
              setAlert(true)
            }
          })
      })();
      return () => { mounted = false }
    }
  }, [smart, t])

  React.useEffect(() => {
    const theData: Entry[] = state.jobs || [];
    setData(theData)
  }, [state])

  const actions = fetch ? [
    // (rowData: Entry): Action<Entry> => ({
    //   disabled: 1 <= processing[rowData.id] || !actionable(rowData),
    //   icon: () => (<div>
    //     {processing[rowData.id] === 2 ? (<CheckCircle />) : (<GetApp />)}{processing[rowData.id] === 1 && (<CircularProgress style={{
    //       position: 'absolute', top: -6, left: -6, zIndex: 1,
    //     }} />)}
    //   </div>),
    //   tooltip: 'Load Resource',
    //   onClick: handleGetResource
    // }),
  ] : [];

  // const canSynd = smart?.onto?.canSynd ? 'TRUE' : 'FALSE';

  return (
    <React.Fragment>
      {/* {
        warn && data
          .filter(entry => 'WARNING' === entry.category)
          .map((entry: Entry, idx) => (
            <Snackbar key={idx} open={warn.includes(entry.id)} autoHideDuration={60000} onClose={(evt, rsn) => handleWarnClose(entry.id, rsn)}>
              <Alert key={idx} onClose={(evt) => handleWarnClose(entry.id)} severity="warning">
                {entry.title}
              </Alert>
            </Snackbar>
          ))
      } */}
      <Snackbar open={alert} autoHideDuration={60000} onClose={handleAlertClose}>
        <Alert onClose={handleAlertClose} severity="error">
          {warn[0] || t('SyndFetchFailure')}
        </Alert>
      </Snackbar>
      {
        Object.keys(outcome).map((id, i) => {
          return (<SnackOutcome key={i} outcome={outcome[id]} />)
        })
      }

      {/* Column type is missing 'width' field, hence the need for a cast */}
      <Card square>
        <MaterialTable
          isLoading={loading}
          title={loading ? tt('Retrieving jobs') : tt('Jobs List')}
          data={data}
          columns={[
            { title: 'Id', field: 'id', },
            { width: '30%', title: t('Title'), field: 'name', sorting: true, defaultSort: 'asc', cellStyle: { maxWidth: '400px', overflow: 'scroll' }, },
            // { title: 'Item', field: 'item', },
            { title: tt('Status'), field: 'status', },
            { width: '50%', title: tt('Detail'), field: 'detail',
              render: rowdata => (
                <ul>
                  {(rowdata.detail||[]).map((item, idx) => (
                    <li key={idx}>{item}</li>
                  ))}
                </ul>
              ),
            },
          ]}
          options={{
            grouping: true,
            maxColumnSort: 1,
            // TODO waiting on pull request in MaterialTable
            // groupTitle: (group: any) => (
            //     <Badge
            //         color='primary'
            //         badgeContent={group.groups.length + group.data.length}
            //         anchorOrigin={{
            //             vertical: 'top',
            //             horizontal: 'left',
            //         }}
            //         max={999}
            //     >
            //         <span style={{ width: '1em' }}>&nbsp;</span>
            //     </Badge>
            // ),
            padding: 'dense',
            pageSize: 50,
          }}
          actions={actions}
          localization={{
            pagination: {
              nextAriaLabel: tt('Next Page'),
              previousAriaLabel: tt('Previous Page'),
              firstAriaLabel: tt('First Page'),
              lastAriaLabel: tt('Last Page')
            },
          }}
        />
      </Card>
    </React.Fragment>
  )
}

export default withTranslation()(Jobs);
