import React, { SyntheticEvent } from 'react';
import { Alert, Snackbar } from '@mui/material';
import { R4 } from '@ahryman40k/ts-fhir-types';

interface SOProps {
    duration?: number;
    outcome: R4.IOperationOutcome;
}

function SnackOutcome(props: SOProps) {
    const { duration = 60000, outcome } = props;

    const [alert, setAlert] = React.useState(outcome.issue?.length > 0);

    const handleAlertClose = (event?: Event | SyntheticEvent<any, Event>, reason?: string) => {
        if (reason === 'clickaway') {
            return
        }
        setAlert(false)
    };

    return (
        <React.Fragment>
            {outcome.issue?.map((issue, i) => {
                let severity: "success" | "info" | "warning" | "error" | undefined;
                switch (issue.severity?.valueOf()) {
                    case "success":
                        severity = 'success'
                        break;
                    case "info":
                        severity = 'info'
                        break;
                    case "warning":
                        severity = 'warning'
                        break;
                    case "error":
                        severity = 'error'
                        break;
                    default:
                        severity = undefined
                }
                return (
                    <Snackbar key={i} open={alert} autoHideDuration={duration} onClose={handleAlertClose}>
                        <Alert onClose={handleAlertClose} severity={severity}><b>{issue.code}</b>: {issue.diagnostics}</Alert>
                    </Snackbar>
                )
            })}
        </React.Fragment>
    );
}

export default SnackOutcome;


