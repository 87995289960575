import { styled } from '@mui/system';
import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import Header from './Header';
import About from './About';
import Syndication from './Syndication';
import { Upload } from './Upload';
import Endpoint from './Endpoint';
import Content from './Content';
import Jobs from './Jobs';

const background = '#eaeff1';

const MainComponent = styled('main')(({ theme }) => ({
    flex: 1,
    padding: theme.spacing(1, 1),
    background: background,
}));

function Body() {

    return (
        <React.Fragment>
            <Header>
                <Endpoint />
            </Header>
            <MainComponent>
                <Switch>
                    <Route key="about" path="/about" component={About} />
                    <Route key="upload-sct" path="/upload-sct" component={Upload} />
                    <Route key="upstream" path="/upstream" children={<Syndication feed="/api/upstream.xml" fetch={true} />} />
                    <Route key="syndication" path="/syndication" children={<Syndication feed="/synd/syndication.xml" fetch={false} />} />
                    <Route key="jobs" path="/jobs" children={<Jobs fetch={false} />} />

                    {/* <Route key="applications" path="/applications" children={<Applications />} />
                    <Route key="shrimp" path="/shrimp/:launch" component={Shrimp} />
                    <Route key="snapper" path="/snapper/:launch" component={Snapper} /> */}

                    {/* <Route path="/category" component={Category} /> */}
                    {/* <Route path="/products" component={Products} /> */}
                    <Redirect from="/resource" to="/resource/default" exact={true} />
                    <Route key="home" path="/resource/:id" children={<Content />} />
                </Switch>
            </MainComponent>
        </React.Fragment>
    );
}

export default Body;
