import React from 'react';
import { AppBar, Grid2, Icon, IconButton, Toolbar, Tooltip, Typography } from '@mui/material';
import { Menu } from '@mui/icons-material';
import { withTranslation, WithTranslation } from 'react-i18next';
import Changelog from './Changelog';
import Languages from './Languages';

interface HeaderProps extends WithTranslation {
  children?: React.ReactNode;
}

function Header(props: HeaderProps) {
  const { t } = props;
  const [changelog, setChangelog] = React.useState(false);

  return (
    <React.Fragment>
      <AppBar color="primary" position="sticky" elevation={0}>
        <Toolbar>
          <Grid2 container spacing={1} alignItems="center" width='100%'>
            <Grid2 sx={{ display: { sm: 'none' } }}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                sx={{ marginLeft: (theme) => -theme.spacing(1) }}
              >
                <Menu/>
              </IconButton>
            </Grid2>
            <Grid2>
              <Typography color="inherit" variant="h5" component="h1">
                {t('dashboardTitle')} ({process.env.REACT_APP_VERSION})
              </Typography>
            </Grid2>
            <Grid2 size="auto"/>
            {props.children}
            {/* <Grid item>
             <Link href="https://ontoserver.csiro.au/docs/" color="inherit" variant="body2">
             {t('goToDocs')}
             </Link>
             </Grid> */}
            <Grid2>
              <Tooltip aria-hidden="false" title="{t('changelog')}" aria-label="Changelog">
                <IconButton color="inherit" onClick={() => setChangelog(true)}>
                  <Icon>newspaper</Icon>
                </IconButton>
              </Tooltip>
            </Grid2>
            <Grid2>
              {/* <Tooltip aria-hidden="false" title="{t('alerts')}" aria-label="Alerts">
               <IconButton color="inherit">
               <NotificationsIcon />
               </IconButton>
               </Tooltip> */}
              <Languages/>
            </Grid2>
            {/* <Grid item>
             <Tooltip title="{t('help')}" aria-label="Help">
             <IconButton color="inherit">
             <HelpIcon />
             </IconButton>
             </Tooltip>
             </Grid> */}
          </Grid2>
        </Toolbar>
      </AppBar>
      <AppBar
        component="div"
        sx={{ zIndex: 0 }}
        color="primary"
        position="static"
        elevation={0}
      >
      </AppBar>
      <Changelog open={changelog} onClose={() => setChangelog(false)}/>
    </React.Fragment>
  );
}

export default withTranslation()(Header);
