import React from 'react';
import SmartContext from './SmartContext';
import { Alert, Switch, Snackbar, Tooltip } from '@mui/material';
import { OfflineBoltOutlined } from '@mui/icons-material';
import HttpStatus from 'http-status-codes';
import { withTranslation, WithTranslation } from 'react-i18next';

interface ResourceDetailsProps extends WithTranslation {
    resource: any; // R4.IResource;
}

export const ResourceSyndication = withTranslation()((props: ResourceDetailsProps) => {
    const { resource, t } = props;
    const [loading, setLoading] = React.useState(false)
    const [syndicated, setSyndicated] = React.useState<boolean|undefined>(undefined)
    const [alert, setAlert] = React.useState<string|undefined>()
    const smart = React.useContext(SmartContext);
    const ontoClient = smart.onto;

    function toggleSyndication() {
        ontoClient?.setSyndStatus(resource.resourceType, resource.id, !syndicated)
            .then(response => {
                if (response.status === 200) {
                    setSyndicated(!syndicated)
                } else {
                    setAlert(HttpStatus.getStatusText(response.status))
                }
            })
    }

    const unknownState = () => {
        return loading || syndicated === undefined;
    }

    const handleAlertClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setAlert(undefined);
    };

    React.useEffect(() => {
        let mounted = true;
        setLoading(true)
        ontoClient?.getSyndStatus(resource.resourceType, resource.id)
            .then(status => {
                if (mounted) {
                    setLoading(false);
                    setSyndicated(status)
                }
            })
            .catch(error => {
                if (mounted) {
                    setLoading(false);
                    console.log('error', error)
                }
            })
        return () => { mounted = false }
    }, [ontoClient, resource]);

    return (unknownState() ? <Tooltip title='unknown state'><OfflineBoltOutlined /></Tooltip> :
        <React.Fragment>
            <Snackbar open={!!alert} autoHideDuration={6000} onClose={handleAlertClose}>
                <Alert onClose={handleAlertClose} severity="error">{alert}</Alert>
            </Snackbar>
            <Switch size="small" checked={syndicated} color="primary" onChange={toggleSyndication} inputProps={{ 'aria-label': t('Toggle Syndication')||undefined }} />
        </React.Fragment>
    )
});
