
import React, { CSSProperties } from 'react';
import SmartContext from './SmartContext';
import MaterialTable, { Column } from '@material-table/core';
import { Narrative } from './Narrative';
import { ResourceDetails } from './ResourceDetails';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Icon } from '@mui/material';
import FhirPatchView from './FhirPatchView';

const BLANK = {
  icon: () => <Icon>none</Icon>,
  disabled: true,
  render: () => <div></div>
};

const rows = (values: any[], prefix: string = ''): any[] => {
  return values.map(historyRow)
}
const historyRow = (value: any): any => {
  // console.log('HISTORY', value);
  const resource = value.resource,
    data = resource ? {
      id: resource.id,
      mv: resource.meta.versionId,
      mu: resource.meta.lastUpdated,
      tp: resource.resourceType,
      t: resource.title || resource.name,
      v: resource.version,
      s: resource.status,
      rm: value.request.method,
      rs: value.response.status,
    } : {
      mv: value.response.etag.substring(3, value.response.etag.length - 1),
      rm: value.request.method,
      rs: value.response.status,
    };
  if ('PUT' === data.rm) {
    if ('201' === data.rs) {
      data.rm = 'PUT (create)'
    }
  }
  return data;
}

interface ResourceHistoryProps extends WithTranslation {
  type: string;
  resource: any; //R4.IResource;
  style?: CSSProperties
}

export const ResourceHistory = withTranslation()((props: ResourceHistoryProps) => {
  const { type, resource, t, style } = props;

  const [loading, setLoading] = React.useState(true)
  const [history, setHistory] = React.useState<any[]>([])
  const smart = React.useContext(SmartContext);
  const fhirClient = smart.client

  const tooltips = {
    changes: t('Changes'),
    narrative: t('Narrative'),
    details: t('Details'),
  };

  React.useEffect(() => {
    let mounted = true
    fhirClient?.request(`${resource.resourceType}/${resource.id}/_history?_summary=true`)
      .then(bundle => {
        // console.log('bundle', bundle.entry)
        if (mounted) {
          setHistory(rows(bundle.entry))
        }
      })
      .catch(error => {
        console.log('error', error)
      })
      .finally(() => {
        if (mounted) {
          setLoading(false);
        }
      })
    return () => { mounted = false }
  }, [fhirClient, resource]);

  return (
    <React.Fragment>
      <MaterialTable
        style={style}
        isLoading={loading}
        columns={[
          { title: t('History'), field: 'mv', width: '10', },
          { title: t('Title'), field: 't', width: '25%', },
          { title: t('Version'), field: 'v', },
          { title: t('Status'), field: 's', },
          { title: t('Request'), field: 'rm', },
          { title: t('Result'), field: 'rs', },
          { title: t('Updated'), field: 'mu', },
        ] as Column<any>[]}
        data={history}
        detailPanel={[
          (rowData: any) => (
            rowData.mv === '1' ? BLANK :
              {
                icon: () => <Icon>difference</Icon>,
                tooltip: rowData.id && tooltips.changes,
                disabled: !rowData.id,
                render: () => {
                  const id = rowData.id;
                  const version = rowData.mv;
                  return (<FhirPatchView type={type} id={id} version={version}></FhirPatchView>)
                }
              }),
          (rowData: any) => ({
            icon: 'note',
            openIcon: 'description',
            tooltip: rowData.id && !rowData.noNarrative && tooltips.narrative,
            disabled: !rowData.id || rowData.noNarrative,
            render: () => {
              return (
                <Narrative type={rowData.tp} id={`${rowData.id}/_history/${rowData.mv}`} noContent={() => {
                  rowData.noNarrative = true;
                }} />
              );
            }
          }),
          (rowData: any) => ({
            tooltip: rowData.id && tooltips.details,
            disabled: !rowData.id,
            render: () => {
              return (
                <ResourceDetails url={`${rowData.tp}/${rowData.id}/_history/${rowData.mv}?_summary=true`} />
              )
            },
          }),
        ]}
        options={{
          grouping: false,
          headerStyle: {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
          },
          padding: 'dense',
          paging: false,
          pageSize: 10,
          search: false,
          selection: false,
          showTitle: false,
          maxColumnSort: 0,
          toolbar: false,
        }}
      />
    </React.Fragment>
  )
});
