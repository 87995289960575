import React from 'react';
import SmartContext from './SmartContext';
import { R4 } from '@ahryman40k/ts-fhir-types';
import { CircularProgress, Card, CardContent, Typography, Box, CardHeader } from '@mui/material';
import ResourceTree from './ResourceTree';
import { Redirect } from 'react-router-dom';
import { withTranslation, WithTranslation } from 'react-i18next';

function CapabilityStatement(props: WithTranslation) {
  const { t } = props;

  const smart = React.useContext(SmartContext);
  // console.log('CapabilityStatement', smart)
  const fhirClient = smart.client;
  const [loading, setLoading] = React.useState(true);
  const [details, setDetails] = React.useState<R4.ICapabilityStatement>({
    resourceType: 'CapabilityStatement',
    title: `${t('Loading')} CapabilityStatement...`,
  });

  React.useEffect(() => {
    fhirClient?.request('/metadata?_format=json')
              .then((cap: R4.ICapabilityStatement) => {
                setDetails(cap);
                setLoading(false);
              })
              .catch((reason) => {
                setDetails({
                  resourceType: 'CapabilityStatement',
                  title: `${t('Failed to load')} CapabilityStatement: ${reason}`,
                });
                console.log('ERROR', reason);
                smart.refresh();
                setLoading(false);
              });
  }, [smart, fhirClient, t]);

  if (smart.error) {
    console.log('REDIRECT', smart.error);
    return (
      <Redirect key="launch" to="/launch"/>
    );
  }

  return (
    <React.Fragment>
      <Card sx={{ minWidth: 275, textAlign: 'left' }} square>
        <CardHeader title={details?.title || details?.name}/>
        {loading ? <CircularProgress/> :
          <CardContent>
            <Typography variant="h6" component="h5">
              {details?.publisher}
            </Typography>
            <Typography variant="body1" component="p">
              {details?.implementation?.description}
            </Typography>
            <Typography variant="body2" component="p">
              {t('Endpoint')}: <span
              style={{ fontSize: '1.0rem', fontFamily: 'monospace' }}>{details?.implementation?.url}</span>
            </Typography>

            <Typography sx={{ mt: 1.5, mb: 1.5 }} color="textSecondary" component="div">
              {t('Contact', { count: details?.contact?.length })}
              <ul>
                {
                  details?.contact?.flatMap(c => c?.telecom?.map(t => t))
                         .filter(t => t)
                         .map((t: any, i: number) => {
                           if ('email' === t.system) {
                             return (<li key={'contact-' + i}>{t.system}: <a
                               href={'mailto:' + encodeURIComponent(t.value)}>{t.value}</a></li>);
                           }
                           return (<li key={'contact-' + i}>{t.system}: {t.value}</li>);
                         })
                }
              </ul>
            </Typography>
            <Typography variant="body2" component="p">
              {t('Software')}:<br/>
              &nbsp;&nbsp;{t('Name')}: {details?.software?.name}<br/>
              &nbsp;&nbsp;{t('Version')}: {details?.software?.version}<br/>
              &nbsp;&nbsp;{t('Release Date')}: {details?.software?.releaseDate}<br/>
              &nbsp;&nbsp;FHIR {t('Version')}: {details?.fhirVersion}<br/>
              &nbsp;&nbsp;{t('Supported Formats')}: <Box component="span"
                                                         fontFamily="Monospace">{details?.format?.join(', ')}</Box>
            </Typography>
            <hr/>
            <ResourceTree resource={{ 'Details': details }}/>
          </CardContent>
        }
      </Card>
    </React.Fragment>
  );
}

export default withTranslation()(CapabilityStatement);
